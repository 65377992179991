import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en/translation.json';
import kr from './kr/translation.json';
import vi from './vi/translation.json';
let browserLang = navigator.language || navigator.userLanguage;
i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en: {
				translation: en,
			},
			ko: {
				translation: kr,
			},
			vi: {
				translation: vi,
			}
		},
		lng: browserLang.substr(0, 2), // if you're using a language detector, do not define the lng option
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		},
	});
