import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import axios from "axios";
import "./locale/i18n";
import { BrowserRouter as Router } from "react-router-dom";
import Constants from "./utils/Constants";

console.info("process.env", process.env);
// const httpClient = axios.create({
//   // baseURL: "http://youradress",
//   baseURL: process.env.REACT_APP_API_BASE_URL,
// });
//
// httpClient.interceptors.request.use(function (config) {
//   config.headers.Authorization =  localStorage.getItem('Authorization') || '';
//   return config;
// });

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
// axios.defaults.headers.common["Authorization"] = localStorage.getItem("Authorization") || "";

// axios set header from localStorage

const ignoreTokenLinks = [
  "/oauth2/apple/login/process",
  "/oauth2/google/login/process"
];
axios.interceptors.request.use(function(config) {

  let isIgnorePath = false;

  for (let i = 0; i < ignoreTokenLinks.length; i++) {
    if (config.url.startsWith(ignoreTokenLinks[i])) {
      isIgnorePath = true;
      break;
    }
  }

  if (isIgnorePath) return config;

  config.headers.Authorization = localStorage.getItem(Constants.LOCAL_STORAGE.AUTHORIZATION) || localStorage.getItem("token") || "";
  return config;
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <App />
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
