import React, {createContext, useContext, useState} from 'react';
import SockJsClient from 'react-stomp';
import {UserContext} from "../Context/UserContext";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import CommonUtils from "../../utils/CommonsUtils";
import Constants from "../../utils/Constants";

const SOCKET_URL = process.env.REACT_APP_API_BASE_URL + '/ws';
const SOCKET_TOPIC = '/topic/message';
const SOCKET_HEADERS = {
    Authorization: localStorage.getItem(Constants.LOCAL_STORAGE.AUTHORIZATION) || ""
};

const defaultWebSocketContext = {}
export const WebSocketContext = createContext(defaultWebSocketContext);

export const WebSocketContextProvider = (props) => {
    let clientRef;
    const [requestWs, setRequestWs] = useState('');
    const { isAuthenticated, setIsAuthenticated, setRole, userData, setUserData, setContextStatus } = useContext(UserContext);
    const { t } = useTranslation();
    const navigate = useNavigate();

    let onConnected = () => {
        console.log("WebSocket Connected!!")
    }

    let onDisConnected = () => {
        console.log("WebSocket Disconnected!!")
    }

    let onMessageReceived = (msgData) => {
        let name = msgData.name;
        let currentLoggedInEmail = userData.email;
        switch (name) {
            case Constants.WEBSOCKET.SOCKET_ADVISOR_REQUEST:
                setRequestWs(msgData.requestCount);
                break;
            case Constants.WEBSOCKET.SOCKET_FORCE_LOGOUT_CLIENT:
                if (currentLoggedInEmail === msgData.emailReceiver) {
                    // Force logout process
                    localStorage.removeItem(Constants.LOCAL_STORAGE.AUTHORIZATION);
                    setIsAuthenticated(false);
                    setRole([]);
                    setUserData({});
                    setContextStatus(Constants.CONTEXT_STATUS.IS_LOGOUT);
                    CommonUtils.displayConfirmAlert(t("login.forceLogoutMessage"), {label: t('button.ok'), onClick : () => (navigate("/admin"))})
                }
                break;
            case Constants.WEBSOCKET.SOCKET_ROLE_UPDATE:
                let roleName = msgData.roleName;
                if (currentLoggedInEmail === msgData.emailReceiver) {
                    setRole([roleName]);
                }
                break;
            default:
                break;
        }
    }

    let sendWebSocketMessage = (msg) => {
        clientRef.sendMessage(SOCKET_TOPIC, msg);
    }

    let onSendMessage = (client) => {
        clientRef = client
    }

    return <WebSocketContext.Provider
        value={{
            requestWs,
            setRequestWs,
            sendWebSocketMessage
        }}
    >
        <div>
            {
                isAuthenticated && <SockJsClient
                    url={SOCKET_URL}
                    headers={SOCKET_HEADERS}
                    topics={[SOCKET_TOPIC]}
                    onConnect={onConnected}
                    onDisconnect={onDisConnected}
                    onMessage={msgData => onMessageReceived(msgData)}
                    ref={(client) => onSendMessage(client)}
                    debug={false}
                />
            }
            {props.children}
        </div>
    </WebSocketContext.Provider>
}

export default WebSocket;
