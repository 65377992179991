import React, {createContext, useEffect, useState} from "react";
import axios from "axios";
import Constants from "../../utils/Constants";
import {useLocation, useNavigate} from "react-router-dom";

/*
const setUserContextReducer = (state, action) => {
    // ...
};*/

const ERR_CODE_RESPONSE = "ERR_BAD_RESPONSE";
const ERR_CODE_REQUEST = "ERR_BAD_REQUEST";
const ERR_STATUS_500 = 500;
const ERR_STATUS_401 = 401;
const ERR_JWT_MESSAGE = "JWT";
const LOGIN_PATH_WITH_SLASH = "/admin/";
const LOGIN_PATH_WITHOUT_SLASH = "/admin";
const defaultUserContext = {}
export const UserContext = createContext(defaultUserContext);

export const UserContextProvider = (props) => {
    const token = localStorage.getItem(Constants.LOCAL_STORAGE.AUTHORIZATION) || '';
    const [isAuthenticated, setIsAuthenticated] = useState(token.length > 0 ? true : false);
    const [contextStatus, setContextStatus] = useState(Constants.CONTEXT_STATUS.IDLE);
    const [role, setRole] = useState([]);
    const [userData, setUserData] = useState({});
    const [companyList, setCompanyList] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (isAuthenticated) {
            const getUserInfo = async () => {
                try {
                    setContextStatus(Constants.CONTEXT_STATUS.LOADING);
                    const resultPromise = await axios.get(`getUserFromToken?token=${token.slice(7)}`)
                        .catch(err=> {
                            if (
                                (err.code === ERR_CODE_RESPONSE && err.response.status === ERR_STATUS_500 && err.response.data.message.includes(ERR_JWT_MESSAGE)) ||
                                (err.code === ERR_CODE_REQUEST && err.response.status === ERR_STATUS_401)
                            ) {
                                // In case of invalid Authorization Code, then removing the code from browser and force navigating to Login Page
                                setContextStatus(Constants.CONTEXT_STATUS.FAILED);
                                localStorage.removeItem(Constants.LOCAL_STORAGE.AUTHORIZATION);
                                setIsAuthenticated(false);
                                setRole([]);
                                setUserData({});
                                navigate("/admin")
                            }
                        });
                    // If user is already logged in and accessing to the Login page, then navigating to User page
                    if (location.pathname === LOGIN_PATH_WITH_SLASH || location.pathname === LOGIN_PATH_WITHOUT_SLASH) {
                        navigate("/admin/users");
                    }
                    axios.get(`ttok_company?size=10000`).then(value => {
                        setCompanyList(value.data._embedded?.ttok_company)
                    })

                    let userInfo = resultPromise.data;
                    setUserData(userInfo.user);
                    setRole(userInfo.role);
                    /*setRole(["SUPER_ADMIN", "VIEWER"]);*/
                    setContextStatus(Constants.CONTEXT_STATUS.SUCCESS);
                } catch (err) {
                    setContextStatus(Constants.CONTEXT_STATUS.FAILED);
                    console.log("Error: " + err.message);
                }
            }
            getUserInfo();
        }
    }, [isAuthenticated, token]);

    const isSuperAdminRole = () => {
        return role.includes(Constants.ROLE.SUPER_ADMIN);
    }

    const isDataAdminRole = () => {
        return role.includes(Constants.ROLE.DATA_ADMIN);
    }

    const isTemplateAdminRole = () => {
        return role.includes(Constants.ROLE.TEMPLATE_ADMIN);
    }

    const isCustomerAdminRole = () => {
        return role.includes(Constants.ROLE.CUSTOMER_ADMIN);
    }

    const isPaymentAdminRole = () => {
        return role.includes(Constants.ROLE.PAYMENT_ADMIN);
    }

    const isViewerRole = () => {
        return role.includes(Constants.ROLE.VIEWER);
    }

    const permissionAccessPageDashBoardAndUsers = () => {
        return isDataAdminRole() || isSuperAdminRole() || isViewerRole();
    }

    const permissionHandlePageUsers = () => {
        return isCustomerAdminRole() || isSuperAdminRole();
    }

    const permissionHandlePageTemplates = () => {
        return isTemplateAdminRole() || isSuperAdminRole();
    }

    const permissionHandlePagePayment = () => {
        return isPaymentAdminRole() || isSuperAdminRole();
    }

    const permissionCanSeeResumeMenu = () => {
        return permissionAccessPageDashBoardAndUsers() || isTemplateAdminRole();
    }

    return <UserContext.Provider
        value={{
            isAuthenticated,
            setIsAuthenticated,
            role,
            setRole,
            userData,
            setUserData,
            contextStatus,
            setContextStatus,
            companyList,
            setCompanyList,
            isSuperAdminRole,
            isDataAdminRole,
            isTemplateAdminRole,
            isCustomerAdminRole,
            isPaymentAdminRole,
            isViewerRole,
            permissionAccessPageDashBoardAndUsers,
            permissionHandlePageUsers,
            permissionHandlePageTemplates,
            permissionHandlePagePayment,
            permissionCanSeeResumeMenu
        }}
    >
        {props.children}
    </UserContext.Provider>
}