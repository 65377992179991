import { confirmAlert } from "react-confirm-alert";

class CommonUtils {
  static mainSiteTitle = "Careermon";

  static displayConfirmAlert(
    message = "You don't have right to access this page",
    okOption = { label: "OK", className: "", onClick: () => {} },
    cancelOption = null
  ) {
    let listButton = [];
    okOption && listButton.push(okOption);
    cancelOption && listButton.push(cancelOption);
    confirmAlert({
      message: message,
      overlayClassName: "custom-ui",
      closeOnClickOutside: false,
      buttons: listButton,
    });
  }

  static displayConfirmAlertHaveNotRightPermission(onCallback = () => {}) {
    confirmAlert({
      message: "You don't have right to access this page",
      overlayClassName: "custom-ui",
      closeOnClickOutside: false,
      buttons: [{ label: "OK", onClick: onCallback }],
    });
  }
}

export default CommonUtils;
