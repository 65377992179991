export default class Constants {
  static BASE_URL = window.location.protocol + "//" + window.location.host;
  static LOCAL_STORAGE = {
    AUTHORIZATION: "Authorization",
    IS_FORCE_LOGIN: "isForceLogin",
    BROWSER_ID: "browserId",
  };

  static HIDE_TITLE = "HIDE_TITLE";

  static CONTEXT_STATUS = {
    IDLE: "IDLE",
    LOADING: "LOADING",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
    IS_LOGOUT: "IS_LOGOUT",
  };

  static FILTER_OPTION = {
    STATUS: "status",
    DETAIL_INFO: "detailInfo",
    REASON_TO_LEAVE: "reasonToLeave",
    TYPE: "type",
    MY_CAREER_OPTION: "myCareerOption",
    RESUME_SEARCH_OPTION: "resumeSearchOption",
    SEARCH_OPTION: "searchOption"
  };

  static FILTER_JOB_OPTION = [
    "ALL_JOBS",
    "FULL_TIME",
    "TEMPORARY",
    "FREELANCER",
    "INTERNSHIP",
    "PART_TIME",
  ];
  static SORT_BY_JOB_OPTION = ["NEWEST_FIRST", "OLDEST_FIRST"];

  static PURPLE_SELECT_STYLE = {
    palette: {
      purple: {
        main: "#CAADFF",
        contrastText: "#000",
        dark: "#CAADFF",
        light: "#CAADFF",
      },
      grey: {
        main: "#C4C1C8",
        contrastText: "#000",
        dark: "#C4C1C8",
        light: "#C4C1C8",
      },
    },
  };

  static RESUME_SEARCH_OPTION = {
    USER: "user",
    MY_CAREER: "myCareer"
  };

  static TYPE_STATUS = {
    ALL: "all",
    REQUEST: "request",
    DENIED: "denied",
    WORKING: "working",
    SENT: "sent",
    ACTIVE: "active",
    DORMANT: "dormant",
    STOPPED: "stopped",
    WITH_DRAWAL: "withdrawal",
    DELETED: "deleted",
    TRIAL: "Trial",
    FREE: "Free",
    PAID: "Paid"
  };

  static SEARCH_OPTION = {
    NAME: "name",
    EMAIL: "email"
  };

  static MY_CAREER_OPTION = {
    ALL: "all",
    CONTACT: "contact",
    SUMMARY: "summary",
    WORK_STORY: "workStory",
    LIFE_STYLE: "lifeStyle",
    SKILL: "skill",
    LANGUAGE: "language",
    CERTIFICATE: "certificate",
    COMPANY: "company",
    PROJECT: "project",
    PORTFOLIO: "portfolio",
    SCHOOL: "school",
    TRAINING: "training"
  };

  static ROLE = {
    ADMIN: "ROLE_ADMIN",
    USER: "ROLE_USER",
    SUPER_ADMIN: "ROLE_SUPER_ADMIN",
    DATA_ADMIN: "ROLE_DATA_ADMIN",
    TEMPLATE_ADMIN: "ROLE_TEMPLATE_ADMIN",
    CUSTOMER_ADMIN: "ROLE_CUSTOMER_ADMIN",
    PAYMENT_ADMIN: "ROLE_PAYMENT_ADMIN",
    VIEWER: "ROLE_VIEWER",
  };

  static WEBSOCKET = {
    SOCKET_FORCE_LOGOUT_CLIENT: "SOCKET_FORCE_LOGOUT_CLIENT",
    SOCKET_ADVISOR_REQUEST: "SOCKET_ADVISOR_REQUEST",
    SOCKET_ROLE_UPDATE: "SOCKET_ROLE_UPDATE",
    SOCKET_JOB_POSTING_STATUS_CHANGED: "SOCKET_JOB_POSTING_STATUS_CHANGED",
    SOCKET_COMPANY_NAME_UPDATE: "SOCKET_COMPANY_NAME_UPDATE",
  };

  static EXCEPTION = {
    ACCOUNT_TYPE: {
      APPLE: "APPLE",
      GOOGLE: "GOOGLE",
    },
    ERROR_TYPE: {
      CONFLICT_ACCOUNT_TYPE: "CONFLICT_ACCOUNT_TYPE",
      CONFLICT_DEVICE_TYPE: "CONFLICT_DEVICE_TYPE",
    },
  };

  static CUSTOM_SCROLL_BAR = {
    "&::-webkit-scrollbar": {
      height: "8px" /* height of horizontal scrollbar ← You're missing this */,
      width: "8px" /* width of vertical scrollbar */,
      border: "1px solid #d5d5d5",
      background: "gray",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: 2,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  };

  static LEAVE_TYPE = {
    ANNUAL: "ANNUAL",
    EXTRA: "EXTRA",
    PUBLIC: "PUBLIC",
    UNPAID: "UNPAID",
  };
}
